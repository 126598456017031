import React from "react"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"

import { createGlobalStyle } from 'styled-components';
import styled from "styled-components"

import _logo from "../assets/logo.png"
import _cover from "../assets/cover.png"

const HTMLStyle = createGlobalStyle`
  /* @import url('https://fonts.googleapis.com/css?family=Kodchasan:400,700'); */
  /* <link href='https://fonts.googleapis.com/css?family=Kodchasan:400,700' rel='stylesheet' type='text/css'> */
  body {
    background-color: #efefef;
    padding: 0;
    margin: 0;
    font-family: Inter, sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  a {
    text-decoration: none;
  }
  ul {
    margin: 0 auto;
    list-style-type: none;
  }

  h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    margin-top: 0;
    margin-bottom: 0;
  }
`

const ViewWrap = styled.div`
  background-color: #fafafa;
  min-height: 100vh;
  max-width: 640px;
  box-sizing: border-box;
  padding: 15px; 15px 0 15px;
  /* display: flex; */
  text-align: center;
`

const NavigationBar = styled.div`
  height: 40px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
`

const NavigationContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Logo = styled.div`
  background: url(${_logo}) no-repeat;
  background-size: cover;
  width: 52.157360406091px;
  height: 25px;
`

const Header = styled.h1`
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 10px;
`
const ProductImage = styled.img`
  border: none;
  width: 80px;
  height: 80px;
  margin-bottom: 15px;
  object-fit: contain;
`
const PriceLists = styled.table`
  width: 100%;
  table-layout: fixed;
  border-style: hidden;
`
const PriceList = styled.tr`
`
const PriceListHeader = styled.thead`
`
const PriceListHeaderItem = styled.th`
  background: #eeeeee;
  font-size: 16px;
  font-weight: 500;
  line-height: 40px;
  height: 40px;
  border: 1px solid #666666;
`
const PriceListBody = styled.tbody`
`
const PriceListItem = styled.td`
  font-size: 16px;
  font-weight: 400;
  line-height: 40px;
  height: 40px;
  border: 1px solid #666666;
`

const WhereToBuy = styled.h2`
  font-size: 20px;
  font-weight: 400;
  margin-top: 15px;
  text-align: left;
`

const Locations = styled.div`
  text-align: left;
`

const Location = styled.div`
  margin-top: 15px;
  border-bottom: 1px solid #000;
`

const LocationDate = styled.div`
  font-size: 12px;
  font-weight: 400;
`

const LocationAddress = styled.div`
  font-size: 16px;
  line-height: 24px;
  margin-top: 5px;
`

const LocationAddressStreet = styled.div`
  font-weight: 500;
`
const LocationAddressCityStatePostalcode = styled.div`
  font-weight: 400;
`
const LocationPrice = styled.div`
  font-weight: 400;
  font-size: 15px;
  margin-top: 3px;
  margin-bottom: 5px;
`

const Sticky = styled.button`
  position: sticky;
  bottom: 0;
  background: #000000;
  color: #ffffff;
  height: 180px;
  font-weight: 500;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px 10px 0 0;
  text-decoration: underline;
  width: 100%;
`

const ProductPage = props => {
  const { pageContext } = props
  const { doc } = pageContext
  const seo = {
    url: `https://www.ainbr.com/${useLocation().pathname}`,
    image: "https://cdn.shopify.com/s/files/1/0276/6290/9518/products/Product_Image_Resize_47_720x@2x.jpg?v=1573796964",
    description: `The fair price of ${doc.product_name} is $${doc.price_amount} in national, and MSRP is $${doc.msrp_price_amount}.`,
    title: doc.title,
  }

  function DownloadApp(e) {
    e.preventDefault()

    const source = "ainbr.com_product_page"
    const store_link = `https://apps.apple.com/app/apple-store/id1516194643?pt=121000177&ct=${source}&mt=8`

    typeof window !== "undefined" && window.gtag && window.gtag("event", "click", {
      "event_category": "ProductPage_Download",
      "event_label": "nearme"
    })

    window.location.href = store_link;
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content={seo.description}/>
        <link href='https://fonts.googleapis.com/css?family=Inter:300,400,600,700,900&display=swap' rel='stylesheet' type='text/css'/>
        <title>{seo.title}</title>

        <meta property="og:title" content={seo.title}/>
        <meta property="og:description" content={seo.description}/>
        <meta property="og:type" content="website"/>
        <meta property="og:locale" content="en_US"/>
        <meta property="og:url" content={seo.url}/>
        <meta property="og:image" content={_cover}/>
        <meta property="og:site_name" content="Ainbr"/>

        <meta property="product:brand" content={seo.product_name}/>
        <meta property="product:availability" content="in stock"/>
        <meta property="product:condition" content="new"/>
        <meta property="product:price:amount" content={doc.price_amount}/>
        <meta property="product:price:currency" content="USD"/>

        <script type="application/ld+json">
        {`{
          "@context": "http://schema.org",
          "@type": "Product",
          "description": "${seo.description}",
          "name": "${seo.product_name}",
          "url": "${seo.url}",
          "brand": "",
          "image": "${seo.image}",
          "offers": {
            "@type": "Offer",
            "url": "${seo.url}",
            "availability": "https://schema.org/InStock",
            "price": "${doc.price_amount}",
            "priceCurrency": "USD"
          }
        }`}
        </script>
      </Helmet>
      <HTMLStyle/>
      <NavigationBar>
        <NavigationContainer>
          <Logo></Logo>
        </NavigationContainer>
      </NavigationBar>
      <ViewWrap>
        <Header>{doc.product_name}</Header>
        <ProductImage src={doc.image_url} alt={doc.product_name}/>
        <PriceLists>
          <PriceListHeader>
            <PriceList>
              <PriceListHeaderItem>Fair Price</PriceListHeaderItem>
              <PriceListHeaderItem>MSRP</PriceListHeaderItem>
            </PriceList>
          </PriceListHeader>
          <PriceListBody>
            <PriceList>
              <PriceListItem>${doc.price_amount}</PriceListItem>
              <PriceListItem>${doc.msrp_price_amount}</PriceListItem>
            </PriceList>
          </PriceListBody>
        </PriceLists>

        <WhereToBuy>Where to buy</WhereToBuy>

        <Locations>
          {doc.locations.map((data, index) => {
            return (
              <Location key={index}>
                <LocationDate>{data.date}</LocationDate>
                <LocationAddress>
                  <LocationAddressStreet>{data.address_street}</LocationAddressStreet>
                  <LocationAddressCityStatePostalcode>{data.address_city_state_postalcode}</LocationAddressCityStatePostalcode>
                </LocationAddress>
                <LocationPrice>${data.price_amount}</LocationPrice>
              </Location>
            )
          })}
        </Locations>
      </ViewWrap>

      <Sticky onClick={DownloadApp}>
        Search stores near me
      </Sticky>
    </>
  )
};

export default ProductPage;
